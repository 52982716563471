import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Sosyal Medya Pazarlama Dünyasına Hoş Geldiniz
			</title>
			<meta name={"description"} content={"Sosyal medyanın gücünü keşfedin, başarıya ulaşın – Pazarlama Akademisi, dijital başarınızın anahtarı"} />
			<meta property={"og:title"} content={"Home | Sosyal Medya Pazarlama Dünyasına Hoş Geldiniz"} />
			<meta property={"og:description"} content={"Sosyal medyanın gücünü keşfedin, başarıya ulaşın – Pazarlama Akademisi, dijital başarınızın anahtarı"} />
			<meta property={"og:image"} content={"https://rynuxgold.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://rynuxgold.com/img/1104745-200.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://rynuxgold.com/img/1104745-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://rynuxgold.com/img/1104745-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://rynuxgold.com/img/1104745-200.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://rynuxgold.com/img/1104745-200.png"} />
			<meta name={"msapplication-TileImage"} content={"https://rynuxgold.com/img/1104745-200.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://rynuxgold.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Sosyal Medya Pazarlama Dünyasına Hoş Geldiniz
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					width="90%"
				>
					Dijital dünyada markanızı öne çıkarmak ve etkileşimi artırmak istiyorsanız doğru yerdesiniz. Pazarlama Akademisi, sosyal medya pazarlama stratejileri konusunda en güncel ve etkili bilgileri sunan online kurslarımızla sizleri bekliyor. Burada, gerçek dünya örnekleri üzerinden uygulamalı öğrenme fırsatı bulacaksınız.
				</Text>
				<Link
					href="/contacts"
					padding="12px 50px 12px 50px"
					color="--light"
					background="--color-primary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Bize Ulaşın
				</Link>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="60px 0 60px 0"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Advantages/Features-27"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box
				border-radius="24px"
				margin="0px 0px 80px 0px"
				md-margin="0px 0px 40px 0px"
				width="55%"
				md-width="80%"
				sm-width="100%"
			>
				<Text
					md-text-align="center"
					margin="0px 0px 16px 0px"
					font="--base"
					color="--primary"
					letter-spacing="1px"
					text-transform="uppercase"
				>
					Avantajlar
				</Text>
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Neden Pazarlama Akademisi?
				</Text>
			</Box>
			<Box
				lg-flex-wrap="wrap"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="36px"
				sm-grid-template-columns="repeat(1, 1fr)"
				sm-grid-gap="8px"
				align-items="center"
			>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Pratik Bilgi
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Gerçek dünya örneklerini kullanarak, teoriden pratiğe geçiş yapın. Bizimle öğrendiklerinizi hemen uygulamaya başlayabilirsiniz.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Esnek Öğrenme Saatleri
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Zamanınızı en verimli şekilde kullanın. Dilediğiniz yerden, dilediğiniz zaman erişim sağlayın.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Uzman Eğitmenler
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Alanında uzman, tecrübeli eğitmenlerimizle sosyal medya pazarlama dünyasının inceliklerini öğrenin.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
					align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Rekabet Avantajı
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Pazarlama Akademisi ile edindiğiniz bilgiler sayesinde rakiplerinizden bir adım öne geçin.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://rynuxgold.com/img/2.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://rynuxgold.com/img/3.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Pazarlama Akademisi – Sosyal Medya Pazarlama Dünyanızı Dönüştürün
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});